import React, { useRef } from 'react';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';

function Contact () {
    const form = useRef();

    const [messageEnvoyé, setMessageEnvoyé] = useState (false);

    const sendEmail = (e) => {
        e.preventDefault();
        setMessageEnvoyé(true);
        alert ("Message envoyé !");
    
        emailjs
        .sendForm('service_zulmn22', 'template_ulqxzuy', form.current, {
            publicKey: 'xv-QHSMOr5yRiFQHw',
        })
        .then(
            () => {
                console.log('Message envoyé !');
                e.target.reset();
            },
            (error) => {
                console.log('Erreur envoi !', error.text);
            }
        );
    };

    return (
        <div className='contact'>
            <h1>Contactez-nous !</h1>
            <div className='contact-container'>
                <form ref={form} onSubmit={sendEmail}>
                    <div>
                        <label>Nom</label>
                        <input type="text" name="user_name" className='input-champ'required/>
                        <label>Email</label>
                        <input type="email" name="user_email" className='input-champ' required='type="email"'/>
                        <label>Message</label>
                        <textarea name="message" className='input-champ' id='input-message'required/>
                    </div>
                    {!messageEnvoyé &&
                        <input type="submit" value="Envoyer" className='button-blue'/>
                    }
                    {messageEnvoyé &&
                        <input type="submit" value="Message envoyé !" className='button-grey'/>
                    }
                </form>
                <div className='mail-container'>
                        <p>Email : contact@meilleur-simulateur-chomage.fr</p>
                </div>
            </div>
        </div>
    );
};

export default Contact