import { Link } from 'react-router-dom'
import './footer.css';
 
function Footer() {
    return (
        <footer>
            <h1>meilleur-simulateur-chomage.fr</h1>
            <nav>
                <Link to="/mentions-legales"><p>Mentions légales</p></Link>
                <p>-</p>
                <Link to="/politique-confidentialite"><p>Politique de confidentialité</p></Link>
            </nav>
            <div className='copyright'>
                <p>Copyright © 2024 meilleur-simulateur-chomage.fr. Tous droits réservés. Conçu par Julien Lefevre</p>
            </div>
        </footer>
    )
}

export default Footer