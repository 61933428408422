import './politique-confidentialite.css';

function PolitiqueConfidentialite() {
    return (
    <div className="politique-confidentialite">
        <h1>Politique de confidentialité</h1>
        <p>Cette Politique de confidentialité décrit la manière dont nous recueillons, utilisons et protégeons les informations que vous nous fournissez lorsque vous utilisez notre site web www.meilleur-simulateur-chomage.fr.</p>
        <div className="mention">
            <h2>Collecte et utilisation des informations</h2>
            <p>Lorsque vous utilisez notre site web, nous pouvons recueillir certaines informations vous concernant afin de vous fournir nos services. Les informations que nous collectons peuvent inclure, mais ne sont pas limitées à :</p>
            <h3>Informations renseignées pour le calcul de l'allocation chômage</h3> 
            <p>Nous collectons les informations suivantes lorsque vous utilisez nos formulaires pour calculer votre allocation chômage :</p>
            <ul>
                <li>Age</li>
                <li>Nombre de jours travaillés</li>
                <li>Durée d'indemnisation</li>
                <li>Salaire</li>
                <li>Type de salaire</li>
                <li>Salaire journalier de référence</li>
                <li>Indemnité journalière</li>
                <li>Date et heure de la simulation</li>
            </ul>
            <p>Ces informations sont stockées de manière anonyme.</p>
            <h3>Adresse e-mail facultative</h3>
            <p>Nous pouvons également collecter votre adresse e-mail si vous choisissez de la fournir dans notre formulaire facultatif à la fin du processus. Cette adresse e-mail peut être utilisée pour vous envoyer de la communication dans le futur, telle qu'une newsletter ou d'autres informations pertinentes. Vous pouvez choisir de vous désabonner de ces communications à tout moment en nous contactant à contact@meilleur-simulateur-chomage.fr.</p>
        </div>
        <div className="mention">
            <h2>Protection des informations</h2>
            <p>Nous attachons une grande importance à la protection de vos informations personnelles et prenons des mesures pour assurer leur sécurité. Nous ne vendons, n'échangeons ni ne transférons vos informations personnelles à des tiers sans votre consentement.</p>
        </div>
        <div className="mention">
            <h2>Cookies et Google Analytics</h2>
            <p>Nous utilisons des cookies pour améliorer votre expérience de navigation sur notre site web. Les cookies sont de petits fichiers texte stockés sur votre ordinateur qui nous permettent de vous reconnaître et de vous fournir des fonctionnalités personnalisées. Vous pouvez choisir de désactiver les cookies dans les paramètres de votre navigateur, mais cela peut affecter certaines fonctionnalités de notre site. Nous utilisons également Google Analytics pour recueillir des informations sur la manière dont les visiteurs utilisent notre site web. Google Analytics utilise des cookies pour collecter des données anonymes sur votre interaction avec le site. Ces données sont utilisées à des fins d'analyse statistique pour améliorer notre site.</p>
        </div>
        <div className="mention">
            <h2>Consentement</h2>
            <p>En utilisant notre site web, vous consentez à notre politique de confidentialité et acceptez de nous fournir les informations nécessaires à l'utilisation de nos services.</p>
        </div>
        <div className="mention">
            <h2>Mises à jour de la politique de confidentialité</h2>
            <p>Nous nous réservons le droit de mettre à jour cette Politique de confidentialité à tout moment. Les modifications apportées à cette Politique de confidentialité seront publiées sur cette page.</p>
            <p>Dernière mise à jour : 06/03/2024</p>
        </div>
        <br />
        <p>Si vous avez des questions ou des préoccupations concernant notre Politique de confidentialité, veuillez nous contacter à contact@meilleur-simulateur-chomage.fr.</p>
    </div>
)} 

export default PolitiqueConfidentialite