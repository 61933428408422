import './mentions-legales.css';

function MentionsLegales() {
    return (
    <div className="mentions-legales">
        <h1>Mentions légales</h1>
        <div className="mention">
            <h2>Informations concernant le site :</h2>
            <ul>
                <li>Directeur de la publication et responsable de la rédaction : Julien Lefevre</li>
                <li>Design : Mayli H</li>
                <li>Contact : contact@meilleur-simulateur-chomage.fr</li>
            </ul>
        </div>
        <div className="mention">
            <h2>Hébergement :</h2>
            <ul>
                <li>Hébergeur : OVH SAS</li>
                <li>Adresse : 2 rue Kellermann, 59100 Roubaix, FRANCE</li>
            </ul>
        </div>
    </div>
)} 

export default MentionsLegales