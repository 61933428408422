import { useState } from 'react';
import './collapse.css';

function Collapse({ title, content, className }) {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleToggle = () => {
      setIsOpen(!isOpen);
    };
  
    return (
        <div className={`${className}`}>
            <div className="collapse-header">
                {isOpen ? (
                    <i class="fa-solid fa-chevron-up" onClick={handleToggle}></i>
                    
                ) : (
                    <i class="fa-solid fa-chevron-down" onClick={handleToggle}></i>
                )}
                <h4 className='collapse-title'>{title}</h4>
            </div>

            {isOpen && (
                <div className="collapse-content">
                    {Array.isArray(content) ? (content.map((item, index) => (
                    <div key={index}>{item}</div>))) : (<div>{content}</div>)}
                 </div>
            )}
        </div>
    );
}
  
export default Collapse;