import { Link } from 'react-router-dom'
import { useState } from 'react'; 
import './header.css';
import logo from '../../assets/logor.png';
 
function Header() {
    const [showMobileNav, setShowMobileNav] = useState(false);

    const toggleMobileNav = () => {
        if (!showMobileNav) {
            setShowMobileNav(true)
        } else {
            setShowMobileNav(false);
        }
    };

    const toggleMobileNavbis = () => {
        setShowMobileNav(false);
    }

    return (
        <header>
            <img src={logo} alt="logo meilleur-simulateur-chomage.fr" id="logo"/>
            <nav className={`${showMobileNav ? 'mobile-nav-active' : ''}`}>
                <div className='link-container'>
                    <Link to="/" className='header-link' onClick={toggleMobileNavbis}>Accueil</Link>
                    <Link to="/contact" className='header-link' onClick={toggleMobileNavbis}>Contact</Link>
                </div>
            </nav>

            <button onClick={toggleMobileNav} className='mobile-nav-button'>
                <span className={`burger-icon ${showMobileNav ? 'open' : ''}`}>&#9776;</span>
            </button>
        </header>
    )
}

export default Header