import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './components/Header/header'
import Home from './pages/Home/home'
import Error from './pages/Error/error'
import SimulateurSimple from './pages/SimulateurSimple/simulateur-simple'
import Footer from './components/Footer/footer'
import Contact from './pages/Contact/contact'
import MentionsLegales from './pages/MentionsLegales/mentions-legales'
import PolitiqueConfidentialite from './pages/PolitiqueConfidentialite/politique-confidentialite'
import './index.css';
 
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/simulation" element={<SimulateurSimple />} />
        <Route path="*" element={<Error />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)