import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import calculator from '../../assets/calculer-la-taxe.png';
import thumbup from '../../assets/pouces-vers-le-haut.png';
import target from '../../assets/cibler.png';


function Home() {
    return (
        <div className='home'>
            <h1>Bienvenue sur le meilleur simulateur d'allocation chômage !</h1>
            <div className='h2-home'>
                <img src={calculator} alt="calculatrice" />
                <h2>Calculez ou recalculer votre allocation chômage rapidement, <strong><u>sans inscription ni mail obligatoire</u></strong>.</h2>
            </div>
            <div className='h2-home'>
                <img src={thumbup} alt="pouce-haut" />
                <h2>Obtenez pour la première fois la formule détaillée de votre indemnité !</h2>
            </div>
            <div className='h2-home'>
                <img src={target} alt="cible" />
                <h2>Résultat rapide :</h2>
            </div>
            <ul>
                <li>1. Calcul de la <strong>durée</strong> de votre indemnisation</li>
                <li>2. Calcul du <strong>montant</strong> de votre indemnité</li>
            </ul>
            <div className='home-link-simulation'>
                <Link to="/simulation">Débuter la simulation</Link>   
            </div>
            <p className='home-legend'>Le résultat obtenu sera une estimation réalisée sur la base des seuls éléments demandés par le simulateur. Votre indemnité pourra dépendre de plus de facteurs non pris en compte ici selon votre cas particulier.</p>
        </div>
    );
}

export default Home;