import './simulateur.css';
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { differenceInDays } from 'date-fns'
import ProgressBar from '../../components/ProgressBar/progress-bar'
import Collapse from '../../components/Collapse/collapse'


function SimulateurSimple() {
    const [age, setAge] = useState('');
    const [resideDOMTOM, setResideDOMTOM] = useState (false);
    const [professionSpeciale, setProfessionSpeciale] = useState (false);
    const [nbMoisAge, setNbMoisAge] = useState ('');
    const [dateDebut, setdateDebut] = useState('');
    const [dateFin, setdateFin] = useState('');  
    const [hasJoursARetirer, setHasJoursARetirer] = useState (null);
    const [joursARetirer, setJoursARetirer] = useState ('');
    const [dateDebutPeriode, setDateDebutPeriode] = useState ('');
    const [nbJoursTravailles, setNbJoursTravailles] = useState ('');
    const [dureeIndemnisation, setDureeIndemnisation] = useState ('');
    const [salaire, setSalaire] = useState ('');
    const [salaireType, setSalaireType] = useState ('monthly');
    const [dernierSalaire, setDernierSalaire] = useState ('');
    const [hasTreiziemeMois, setHasTreiziemeMois] = useState (null);
    const [primes, setPrimes] = useState ('');
    const [montantPrimes, setMontantPrimes] = useState ('');
    const [hasRTT, setHasRTT] = useState(null);
    const [RTTValue, setRTTValue] = useState('');
    const [RTTType, setRTTType] = useState('days');
    const [hasCET, setHasCET] = useState(null);
    const [CETValue, setCETValue] = useState('');
    const [CETType, setCETType] = useState('days');
    const [salaireJournalier, setSalaireJournalier] = useState('');
    const [salairePeriode, setSalairePeriode] = useState('');
    const [montantRTT, setMontantRTT] = useState('');
    const [montantCET, setMontantCET] = useState('');
    const [SJR, setSJR] = useState('');
    const [plafondIndemnite, setPlafondIndemnite] = useState ('');
    const [indemniteJournaliere, setIndemniteJournaliere] = useState('');
    const [indemniteMensuelle, setIndemniteMensuelle] = useState('');
    const [mail, setMail] = useState('');

    const [ageSoumis, setAgeSoumis] = useState(false);
    const [afficherPeriode, setAfficherPeriode] = useState(false);
    const [periodeConfirmee, setPeriodeConfirmee] = useState(false);
    const [afficherBoutonPeriode, setAfficherBoutonPeriode] = useState(true);
    const [afficherBoutonSuivantJours, setAfficherBoutonSuivantJours] = useState (false);
    const [modaleJours, setModaleJours] = useState (false);
    const [afficherDuree, setAfficherDuree] = useState (false);
    const [afficherSalaire, setAfficherSalaire] = useState (false);
    const [afficherBoutonSuivantMois, setAfficherBoutonSuivantMois] = useState (false);
    const [afficherPrime, setAfficherPrime] = useState (false);
    const [afficherRTT, setAfficherRTT] = useState (false);
    const [afficherBoutonSuivantRTT, setAfficherBoutonSuivantRTT] = useState (false);
    const [afficherBoutonSuivantCET, setAfficherBoutonSuivantCET] = useState (false);
    const [afficherIndemnite, setAfficherIndemnite] = useState (false);
    const [afficherExemples, setAfficherExemples] = useState (false);
    const [mailEnvoyé, setMailEnvoyé] = useState (false);

    //Calcul de la durée d'indemnisation étape 1 sur 3
    const soumissionAge = (e) => {
        e.preventDefault();

        if (age<0) {
            alert("Votre âge doit être supérieur à 0.")
        } else {
            let nbMoisAge;
            if (age<53) {
                nbMoisAge = 24;
            } else {
                nbMoisAge = 36;
            }
            setNbMoisAge(nbMoisAge);
            setAgeSoumis(true);
            setAfficherPeriode(true);
        }
    }

    //Calcul de la durée d'indemnisation étape 2 sur 3
    const funAfficherExemples = (e) => {
        e.preventDefault();

        setAfficherExemples(true);
    }

    const masquerExemples = (e) => {
        e.preventDefault();

        setAfficherExemples(false);
    }

    const retourAge = () => {
        setAfficherPeriode(false);
        setAgeSoumis(false);
    }

    const soumissionPeriode = (e) => {
        e.preventDefault();

        if (differenceInDays(dateFin, dateDebut) < 182) {
            alert("Vous devez avoir travaillé au minimum 6 mois. Vous pouvez renseigner le premier jour où vous étiez en contrat d'un travail précédent durant cette période s'il n'a pas déjà ouvert de droit au chômage.");
        } else {
            confirmerPeriode ();
        }
    }

    const confirmerPeriode = () => {
        setAfficherBoutonPeriode(false);
        setAfficherPeriode(false);
        setPeriodeConfirmee(true);
        setAfficherExemples(false);

        let dateDebutPeriode;
        if (age < 53) {
            dateDebutPeriode = Math.max(dateFin - (differenceInDays(dateFin, dateDebut)*86400000), dateFin - 730*86400000);
        } else {
            dateDebutPeriode = Math.max(dateFin - (differenceInDays(dateFin, dateDebut)*86400000), dateFin - 1095*86400000);
        }
        setDateDebutPeriode(dateDebutPeriode);
    }

    //Calcul de la durée d'indemnisation étape 3 sur 3
    const afficherEvenements = (e) => {
        e.preventDefault();

        setModaleJours(true);
    }

    const masquerEvenements = (e) => {
        e.preventDefault();
        
        setModaleJours(false);
    }

    const handleBoutonJoursOuiClick = () => {
        if (hasJoursARetirer) {
            setHasJoursARetirer(null);
            setAfficherBoutonSuivantJours (false);
        } else if (hasJoursARetirer === false) {
            setAfficherBoutonSuivantJours (false);
            setHasJoursARetirer(true);
        } else {
        setHasJoursARetirer(true);
        }
    }

    const handleBoutonJoursNonClick = () => {
        if (hasJoursARetirer === false) {
            setHasJoursARetirer(null);
            setAfficherBoutonSuivantJours (false);
        } else {
        setJoursARetirer ('');
        setHasJoursARetirer (false);
        setAfficherBoutonSuivantJours (true);
        }
    }

    const retourPeriode = () => {
        setAfficherBoutonSuivantJours (false);
        setJoursARetirer ('');
        setHasJoursARetirer (null);
        setPeriodeConfirmee(false);
        setAfficherPeriode(true);
        setAfficherBoutonPeriode(true);
    }

    const calculDuree = (e) => {
        e.preventDefault();

        let nbJoursTravailles;
        nbJoursTravailles = ((dateFin - dateDebutPeriode - joursARetirer*86400000)/86400000).toFixed(0);
        setNbJoursTravailles(nbJoursTravailles);
        
        let dureeIndemnisation;
        if (!resideDOMTOM && !professionSpeciale) {
            dureeIndemnisation = Math.max(182, (0.75*nbJoursTravailles)).toFixed(0);
        } else {
            dureeIndemnisation = Math.max(182, nbJoursTravailles).toFixed(0);
        }
        setDureeIndemnisation(dureeIndemnisation);

        if (joursARetirer < 0.75*((dateFin - dateDebutPeriode - joursARetirer*86400000)/86400000)) {
            setPeriodeConfirmee(false);
            setAfficherDuree(true);
        } else {
            alert("Le nombre de jours à retirer est trop élevé. Il dépasse 75% du nombre de jours travaillés.");
        }
        setModaleJours(false);
    }

    //Calcul de la durée d'indemnisation Résultat
    const retourJours = () => {
        setAfficherDuree(false);
        setPeriodeConfirmee(true);
    }

    const passageSalaire = (e) => {
        e.preventDefault();

        setAfficherDuree(false);
        setAfficherSalaire (true);
    }

    //Calcul du montant de l'indemnite étape 1 sur 3
    const retourDuree = () => {
        setAfficherSalaire (false);
        setAfficherDuree(true);
    }

    const handleBoutonMoisOuiClick = () => {
        if (hasTreiziemeMois) {
            setHasTreiziemeMois(null);
            setAfficherBoutonSuivantMois (false);
        } else {
        setHasTreiziemeMois(true);
        setAfficherBoutonSuivantMois (true);
        }
    }

    const handleBoutonMoisNonClick = () => {
        if (hasTreiziemeMois === false) {
            setHasTreiziemeMois(null);
            setAfficherBoutonSuivantMois (false);
        } else {
        setHasTreiziemeMois (false);
        setAfficherBoutonSuivantMois (true);
        }
    }

    const soumissionSalaire = (e) => {
        e.preventDefault();

        if (salaire >0 && dernierSalaire >0) {
            let salaireJournalier;
            if (salaireType === 'monthly') {
                const salaireMensuel = parseFloat(salaire);
                salaireJournalier = hasTreiziemeMois ? ((salaireMensuel * 13) / 365) : ((salaireMensuel * 12) / 365);
            } else if (salaireType === 'annual') {
                const salaireAnnuel = parseFloat(salaire);
                salaireJournalier = (salaireAnnuel / 365);
            }
            setSalaireJournalier(salaireJournalier);

            let salairePeriode;
            salairePeriode = (salaireJournalier*nbJoursTravailles).toFixed(2);
            setSalairePeriode(salairePeriode);

            setAfficherSalaire(false);
            setAfficherPrime(true);
        } else {
            alert ("Votre salaire doit être supérieur à 0.")
        }
    }

    //Calcul du montant de l'indemnite étape 2 sur 3
    const retourSalaire = () => {
        setAfficherPrime(false);
        setAfficherSalaire(true);
    }

    const soumissionPrime = (e) => {
        e.preventDefault();

        let montantPrimes;
        if (!primes) {
            montantPrimes = 0;
        } else {
            montantPrimes = parseFloat(primes);
        }
        setMontantPrimes(montantPrimes);

        setAfficherPrime (false);
        setAfficherRTT (true);        
    }

    //Calcul du montant de l'indemnite étape 3 sur 3
    const retourPrime = () => {
        setAfficherBoutonSuivantRTT (false);
        setAfficherBoutonSuivantCET (false);
        setHasRTT (null);
        setHasCET (null);
        setAfficherRTT(false);
        setAfficherPrime(true);
    }

    const handleBoutonRTTOuiClick = () => {
        if (hasRTT) {
            setHasRTT(null);
            setAfficherBoutonSuivantRTT (false);
        } else if (hasRTT === false) {
            setAfficherBoutonSuivantRTT (false);
            setHasRTT(true);
        } else {
        setHasRTT(true);
        }
    }

    const handleBoutonRTTNonClick = () => {
        if (hasRTT === false) {
            setHasRTT(null);
            setAfficherBoutonSuivantRTT (false);
        } else {
        setRTTValue('');
        setHasRTT(false);
        setAfficherBoutonSuivantRTT (true);
        }
    }
    
    const handleBoutonCETOuiClick = () => {
        if (hasCET) {
            setHasCET(null);
            setAfficherBoutonSuivantCET (false);
        } else if (hasCET === false) {
            setAfficherBoutonSuivantCET (false);
            setHasCET(true);
        } else {
        setHasCET(true);
        }
    }

    const handleBoutonCETNonClick = () => {
        if (hasCET === false) {
            setHasCET(null);
            setAfficherBoutonSuivantCET (false);
        } else {
        setCETValue('');
        setHasCET(false);
        setAfficherBoutonSuivantCET (true);
        }
    }

    const calculAllocation = async (e) => {
        e.preventDefault();
        
        let montantRTT;
        if (!RTTValue) {
            montantRTT = 0;
        } else if (RTTType === 'days') {
            const RTTNumber = parseFloat(RTTValue);
            montantRTT = hasTreiziemeMois ? (RTTNumber * dernierSalaire * 13/260).toFixed(2) : (RTTNumber * dernierSalaire * 12/260).toFixed(2) ;
        } else if (RTTType === 'amount') {
            montantRTT = parseFloat(RTTValue);
        }
        setMontantRTT(montantRTT);

        let montantCET;
        if (!CETValue) {
            montantCET = 0;
        } else if (CETType === 'days') {
            const CETNumber = parseFloat(CETValue);
            montantCET = hasTreiziemeMois ? (CETNumber*dernierSalaire * 13/260).toFixed(2) : (CETNumber*dernierSalaire * 12/260).toFixed(2);
        } else if (CETType === 'amount') {
            montantCET = parseFloat(CETValue);
        }
        setMontantCET(montantCET);

        let SJR;
        SJR = ((parseFloat(salairePeriode) + parseFloat(montantPrimes) + parseFloat(montantRTT) + parseFloat(montantCET))/ parseFloat(nbJoursTravailles)).toFixed(2);
        setSJR(SJR);

        let plafondIndemnite;
        plafondIndemnite = parseFloat((SJR * 0.75).toFixed(2));
        setPlafondIndemnite (plafondIndemnite);

        let indemniteJournaliere;
        const calculIndemniteJournaliere = Math.max((SJR*0.57),(12.95+SJR*0.4040)).toFixed(2);

        if ((calculIndemniteJournaliere > plafondIndemnite) && ((calculIndemniteJournaliere > 31.59) && (calculIndemniteJournaliere < 289.64))) {
            indemniteJournaliere = plafondIndemnite;
        } else if (calculIndemniteJournaliere < 31.59) {
            indemniteJournaliere = 31.59;
        } else if (calculIndemniteJournaliere > 289.64) {
            indemniteJournaliere = 289.64;
        } else {
            indemniteJournaliere = calculIndemniteJournaliere;
        }
        setIndemniteJournaliere (indemniteJournaliere);

        let indemniteMensuelle;
        indemniteMensuelle = (indemniteJournaliere*30).toFixed(2);
        setIndemniteMensuelle (indemniteMensuelle);

        //DEBUT ENVOI REQUETE POST
        const user = {
            userType: 'user',
            age: age,
            nb_jours_travailles: nbJoursTravailles,
            duree_indemnisation:dureeIndemnisation,
            salaire: salaire,
            salaire_type: salaireType,
            sjr: SJR,
            indemnite_journaliere: indemniteJournaliere
        };

        try {
            const response = await fetch('https://www.meilleur-simulateur-chomage.fr/api/user/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            });
    
            if (response.ok) {
                console.log('Utilisateur créé avec succès !');
            } else {
                console.error('Erreur lors de la création de l\'utilisateur : ', response.statusText);
            }
        } catch (error) {
            console.error('Erreur lors de la requête POST : ', error);
        }
        //FIN REQUETE POST

        setAfficherRTT (false);   
        setAfficherIndemnite (true);
    }

    //Calcul du montant de l'indemnite Résultat
    const retourRTT = () => {
        setAfficherIndemnite(false);
        setAfficherRTT(true);
        setMailEnvoyé(false);
    }

    const handlePrint = (e) => {
        e.preventDefault();
        window.print();
    }

    function dateToString(date, format) {
        return new Intl.DateTimeFormat('fr-FR', { dateStyle: format }).format(date);
    }

    const envoiMail = async (e) => {
        e.preventDefault();
        alert('Mail envoyé !');

        const userMail = {
            userType: 'userMail',
            mail: mail
        }

        try {
            const response = await fetch('https://www.meilleur-simulateur-chomage.fr/api/user/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userMail)
            });
    
            if (response.ok) {
                console.log('Utilisateur créé avec succès !');
                setMailEnvoyé(true);
            } else {
                console.error('Erreur lors de la création de l\'utilisateur : ', response.statusText);
            }
        } catch (error) {
            console.error('Erreur lors de la requête POST : ', error);
        }
    }

return (
<div className='simulateur'>
    {!ageSoumis && (
        <form onSubmit = {soumissionAge}>
        <div className='step-container'>
            <h1>Calcul de la durée d'indemnisation</h1>
            <p>Étape 1 sur 3</p>
            <ProgressBar step={1} />
        </div>
        <div>
            <div className='label-container'>
                <label>Veuillez entrer votre âge*</label>
                <input className='input-champ'
                    type="number"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    required
                />
            </div>   
            <p>Cas spécifiques :</p>
            <br />
            <div className='label-container-box'>
                <input
                    type="checkbox"
                    checked={resideDOMTOM}
                    onChange={() => setResideDOMTOM(!resideDOMTOM)}
                    className='input-box'
                />
                <label className='label-p'>Je réside à la Réunion, en Guadeloupe, Guyane, Martinique, Saint-Pierre-et-Miquelon, Saint-Barthélémy, Saint-Martin.</label>
            </div>
            <div className='label-container-box'>
                <input
                    type="checkbox"
                    checked={professionSpeciale}
                    onChange={() => setProfessionSpeciale(!professionSpeciale)}
                    className='input-box'
                />
                <label className='label-p'>J'exerce la profession suivante : intermittents du spectacle, marins-pêcheurs, ouvriers-dockers occasionnels.</label>
            </div>

            <div className='button-container'>
                <button type="submit" className='button-blue'>Suivant<i class="fa-solid fa-arrow-right"></i></button>
            </div>

            <div className='div-legend'>
                <p>*Champ obligatoire.</p>
            </div>
        </div>
        </form>
    )}

    {afficherPeriode && (
        <form onSubmit={soumissionPeriode}>
        <div className='step-container'>
            <h1>Calcul de la durée d'indemnisation</h1>
            <p className='p-step'>Étape 2 sur 3</p>
            <ProgressBar step={2} />
        </div>
        <div>
            {age<53 && ( 
            <h2>Vous avez moins de 53 ans. La période de référence pour calculer la durée de votre allocation est donc de <u>{nbMoisAge} mois</u>.</h2>
            )}
            {age>=53 && ( 
            <h2>Vous avez 53 ans ou plus. La période de référence pour calculer la durée de votre allocation est donc de <u>{nbMoisAge} mois</u>.</h2>
            )}
            
            <h3>Veuillez renseigner votre période de travail :</h3>
            {!afficherExemples && (
            <div>
                <button onClick={funAfficherExemples} className='bouton-exemples'>Voir les différents exemples de périodes de travail</button>
            </div>
            )}
            {afficherExemples && (
                <div>
                    <button onClick={masquerExemples} className='bouton-exemples'>Masquer les exemples</button>
                    <div>
                        <div className='collapse-container'>
                            <Collapse title={`Cas n°1 : votre dernier contrat dure depuis PLUS longtemps que ${nbMoisAge} mois`} 
                            content={["Renseignez simplement les dates de début et de fin de votre contrat."]} 
                            className="collapse1" 
                            />
                            <Collapse title={`Cas n°2 : votre dernier contrat dure depuis MOINS longtemps que ${nbMoisAge} mois et vous n'avez pas eu de précédent emploi durant cette période OU ceux-ci ont déjà ouvert un droit au chômage.`} 
                            content={["Renseignez simplement les dates de début et de fin de votre dernier contrat."]} 
                            className="collapse2" 
                            />
                            <Collapse title={`Cas n°3 : votre dernier contrat dure depuis MOINS longtemps que ${nbMoisAge} mois et vous avez eu des précédents emplois durant cette période qui n'ont PAS déjà ouvert de droit au chômage.`} 
                            content={[`Vous pouvez renseigner la date de début du premier contrat des ${nbMoisAge} derniers mois qui n'a pas encore ouvert de droit au chômage et la date de fin de votre dernier contrat.`,
                            `Par exemple dans le cas de 3 contrats différents :`,
                            `- Date du jour : 01/01/2024`,
                            `- Date contrat 1 : du 01/01/2020 au 31/06/2022 (durée 2 ans et demi)`,
                            `- Date contrat 2 : du 01/07/2022 au 31/06/2023 (durée 1 an)`,
                            `- Date contrat 3 : du 01/07/2023 au 31/12/2024 (durée 6 mois)`,
                            "Si aucun de ces contrats n'a ouvert de droit au chômage, vous pouvez renseigner en Date de début de contrat 01/01/2020 et en Date de fin de contrat 31/12/2024.",
                            "ATTENTION à bien déclarer les jours non travaillés entre chaque contrat le cas échéant à la prochaine étape (3 sur 3)."]}
                            className="collapse3" 
                            />
                        </div>
                    </div>
                    
                </div>
            )}
            <br />

            <div>
                <div className='label-container-box'>
                    <label>Date de début de contrat* </label>
                    <DatePicker className='input-champ'
                        selected={dateDebut}
                        onChange={(date) => setdateDebut(date)}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                </div>
                <div className='label-container-box'>
                    <label>Date de fin de contrat* </label>
                    <DatePicker className='input-champ'
                        selected={dateFin}
                        onChange={(date) => setdateFin(date)}
                        dateFormat="dd/MM/yyyy"
                        required
                    />
                </div>
            </div>

            {afficherBoutonPeriode && (
                <div className='button-container'>
                    <button type="button" onClick={retourAge} className='button-blue'><i class="fa-solid fa-arrow-left"></i>Précédent</button>
                    <button type="submit" className='button-blue' id='button-right'>Suivant <i class="fa-solid fa-arrow-right"></i></button>
                </div>
            )}    

            <div className='div-legend'>
                <p>*Champs obligatoires.</p>
            </div>
        </div>
        </form>
    )}

    {periodeConfirmee && (
        <form onSubmit={calculDuree}>
        <div className='step-container'>
            <h1>Calcul de la durée d'indemnisation</h1>
            <p className='p-step'>Étape 3 sur 3</p>
            <ProgressBar step={3} />
        </div>
        <div>
            <h2>La période de travail retenue pour le calcul de votre allocation est {dateToString(dateDebutPeriode, 'short')} - {dateToString(dateFin, 'short')} </h2>
            <p>Les jours d'inactivité professionnelle et ceux correspondant à certains événements doivent être retirés de cette période.</p>
            <br />
            <div>
            {!modaleJours && (
            <button onClick={afficherEvenements} className='bouton-exemples'>En savoir + sur ces événements</button>
            )}

            {modaleJours && (
                <div>
                    <button onClick={masquerEvenements} className='bouton-exemples'>Masquer les événements</button>
                    <div>
                        <h3>Certains événements sont retirés du nombre de jours retenus pour le calcul de la durée d’indemnisation.</h3>
                        <div className='evenements'>
                            <p>Les périodes d'activité professionnelle que vous n’avez pas déclarées à Pôle emploi.</p>
                            <br />
                            <p>Les jours correspondant à certains événements qui ont lieu en dehors du contrat de travail :</p>
                            <ul className='liste-evenements'>
                                <li>Les périodes de congé maternité et les périodes de maternité, indemnisées au titre de la prévoyance.</li>
                                <li>Les périodes d'indemnisation accordées à la mère ou au père adoptif.</li>
                                <li>Les périodes d'arrêt maladie d'une durée supérieure à 15 jours consécutifs.</li>
                                <li>Les périodes d'accident du travail.</li>
                                <li>Les périodes de maladie d'origine professionnelle.</li>
                                <li>Les périodes de congé paternité et d'accueil de l'enfant indemnisés.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
            </div>
            
            <div className='jours-retires'>
                <h3>Avez-vous des jours à retirer de cette période ?*</h3>
                <div className='checkbox-container'>
                    <input
                        type="checkbox"
                        checked={hasJoursARetirer === true}
                        onChange={handleBoutonJoursOuiClick}
                        className='input-box'
                    />
                    <label className='label-jours'>Oui</label>
                    <input
                        type="checkbox"
                        checked={hasJoursARetirer === false}
                        onChange={handleBoutonJoursNonClick}
                        className='input-box'
                    />
                    <label className='label-jours'>Non</label>
                </div>
            </div>

            {hasJoursARetirer && (
            <div className='label-container' id='label-container-jours'>
                <label className='label-nbjours'>Indiquez le nombre de jours à retirer* </label>
                <input className='input-champ'
                type="number"
                value={joursARetirer}
                onChange={(e) => {
                    const nbJoursARetirer = e.target.value;
                    if (nbJoursARetirer>=0 && nbJoursARetirer!=='') {
                    setAfficherBoutonSuivantJours(true);
                    } else {
                    setAfficherBoutonSuivantJours(false);
                    }
                    setJoursARetirer(nbJoursARetirer);
                }}
                required
                />
            </div>
            )}

            <div className='button-container'>
                <button type="button" onClick={retourPeriode} className='button-blue'><i class="fa-solid fa-arrow-left"></i>Précédent</button>
                {!afficherBoutonSuivantJours && (
                    <button type="submit" className='button-grey' id='button-right'>Suivant<i class="fa-solid fa-arrow-right"></i></button>
                )}
                {afficherBoutonSuivantJours && (
                    <button type="submit" className='button-blue' id='button-right'>Suivant<i class="fa-solid fa-arrow-right"></i></button>
                )}
            </div>

            <div className='div-legend'>
                <p>*Champ obligatoire.</p>
            </div>
        </div>
        </form>
    )}

    {afficherDuree && (
        <form onSubmit={passageSalaire}>
        <div>
            <h1>Votre durée d'indemnisation sera de {dureeIndemnisation} jours.</h1>
            <br />

            <p>Elle a été calculée de la manière suivante :</p>
            <br />
            <div class='result-box'>
                {(!resideDOMTOM && !professionSpeciale) && (
                    <p className='result'>0,75 * (Période travaillée - nb de jours à retirer durant les {nbMoisAge} mois précédant la fin de votre contrat)</p>
                )}
                {(resideDOMTOM || professionSpeciale) && (
                    <p className='result'>Période travaillée - nb de jours à retirer durant les {nbMoisAge} mois précédant la fin de votre contrat</p>
                )}
            </div>

            <div className='button-container' id='button-margin'>
                <button type="button" onClick={retourJours} className='button-blue'><i class="fa-solid fa-arrow-left"></i>Précédent</button>
                <button type="submit" className='button-blue' id='button-long'>Passer au montant<i class="fa-solid fa-arrow-right"></i></button>
            </div>
        </div>
        </form>
    )}

    {afficherSalaire && (
        <div>
            <form onSubmit={soumissionSalaire}>
            <div className='step-container'>
                <h1>Calcul du montant de l'indemnité</h1>
                <h2>(Période de référence {dateToString(dateDebutPeriode, 'short')} - {dateToString(dateFin, 'short')})</h2>
                <p className='p-step'>Étape 1 sur 3</p>
                <ProgressBar step={1} />
            </div>
            <div>      
                <div className='div-select'>
                    <label>Salaire brut moyen durant la période de référence*</label>
                    <div className='input-select-container'>
                        <input className='input-champ' id='input-champ-select'
                            type="number"
                            value={salaire}
                            onChange={(e) => setSalaire(e.target.value)}
                            required
                        />
                        <select className='select-box'
                            value={salaireType}
                            onChange={(e) => setSalaireType(e.target.value)}
                        >
                            <option value="monthly">Mensuel</option>
                            <option value="annual">Annuel</option>
                        </select>
                    </div>
                </div>
                <br />
                <div className='div-legend'>
                    <p>Attention de bien prendre en compte tous vos salaires si vous avez eu plusieurs emplois durant votre période de référence (cas 3 de l'étape 2 sur 3 du Calcul de la durée d'indemnisation).</p>
                </div>
                <br />
                <div className='label-container'>
                    <label>Dernier salaire mensuel brut complet*</label>
                    <input className='input-champ'
                        type="number"
                        value={dernierSalaire}
                        onChange={(e) => setDernierSalaire(e.target.value)}
                        required
                    />
                </div>
                <div className='treizieme-mois'>
                    <h3>Avez-vous un 13ème mois* ?</h3>
                    <div className='checkbox-container'>
                        <input
                            type="checkbox"
                            checked={hasTreiziemeMois === true}
                            onChange={handleBoutonMoisOuiClick}
                            className='input-box'
                        />
                        <label className='label-jours'>Oui</label>
                        <input
                            type="checkbox"
                            checked={hasTreiziemeMois === false}
                            onChange={handleBoutonMoisNonClick}
                            className='input-box'
                        />
                        <label className='label-jours'>Non</label>
                    </div>
                </div>

                <div className='button-container'>
                    <button type="button" className='button-blue' id='button-long' onClick={retourDuree}><i class="fa-solid fa-arrow-left"></i>Revenir à la durée</button>
                    {!afficherBoutonSuivantMois && (
                        <button type="sumbit" className='button-grey' id='button-right'>Suivant<i class="fa-solid fa-arrow-right"></i></button>
                    )}
                    {afficherBoutonSuivantMois && (
                        <button type="sumbit" className='button-blue' id='button-right'>Suivant<i class="fa-solid fa-arrow-right"></i></button>
                    )}
                </div>
            </div>
            </form>

            <div className='div-legend'>
                <p>*Champs obligatoires.</p>
            </div>  
        </div>    
    )}

    {afficherPrime && (
        <form onSubmit={soumissionPrime}>
        <div className='step-container'>
            <h1>Calcul du montant de l'indemnité</h1>
            <h2>(Période de référence {dateToString(dateDebutPeriode, 'short')} - {dateToString(dateFin, 'short')})</h2>
            <p className='p-step'>Étape 2 sur 3</p>
            <ProgressBar step={2} />
        </div >
        <div className='label-container'>
            <label>Somme des <u>primes¹</u> reçues durant votre période de référence</label>
            <input className='input-champ'
                type="number"
                value={primes}
                onChange={(e) => setPrimes(e.target.value)}
            />
        </div>
        
        <p className='p-legend'>¹Prendre en compte UNIQUEMENT les primes directement liées au contrat de travail sur lequelles des cotisations ont été payées et les primes exceptionnelles de pouvoir d'achat (primes Macron).</p>
        <br />

        <div className='div-legend'>
            <p>Attention de bien prendre en compte toutes les primes reçues si vous avez eu plusieurs emplois durant votre période de référence (cas 3 de l'étape 2 sur 3 du Calcul de la durée d'indemnisation).</p>
        </div>
        
        <div className='button-container'>
            <button type="button" onClick={retourSalaire} className='button-blue'><i class="fa-solid fa-arrow-left"></i>Précédent</button>
            <button type="submit" className='button-blue' id='button-right'>Suivant<i class="fa-solid fa-arrow-right"></i></button>
        </div>
        </form>
    )}

    {afficherRTT && (
        <form onSubmit={calculAllocation}>
        <div className='step-container'>
            <h1>Calcul du montant de l'indemnité</h1>
            <h2>(Période de référence {dateToString(dateDebutPeriode, 'short')} - {dateToString(dateFin, 'short')})</h2>
            <p className='p-step'>Étape 3 sur 3</p>
            <ProgressBar step={3} />
        </div>
        <div >
            <div className='div-rtt'>
                <h3>Avez-vous des RTT non pris qui vous seront payés à la fin de votre contrat ?*</h3>
                <div className='div-select'>
                    <div className='checkbox-container' id='checkbox-container-line'>
                        <input
                            type="checkbox"
                            checked={hasRTT === true}
                            onChange={handleBoutonRTTOuiClick}
                            className='input-box'
                            id='input-box-mois'
                        />
                        <label className='label-jours' id='label-mois'>Oui</label>
                        <input
                            type="checkbox"
                            checked={hasRTT === false}
                            onChange={handleBoutonRTTNonClick}
                            className='input-box'
                            id='input-box-mois'
                        />
                        <label className='label-jours' id='label-mois'>Non</label>
                    </div>

                    <div className='input-select-container'>
                        {hasRTT && (
                        <>
                            <input className='input-champ' id='input-champ-select'
                            type="number"
                            value={RTTValue}
                            onChange={(e) => {
                                const RTTValue = e.target.value;
                                if (RTTValue>=0 && RTTValue!== '') {
                                setAfficherBoutonSuivantRTT(true);
                                } else {
                                setAfficherBoutonSuivantRTT(false);
                                }
                                setRTTValue(RTTValue);
                            }}
                            />
                            <select className='select-box' id='select-box-long'
                            value={RTTType}
                            onChange={(e) => setRTTType(e.target.value)}
                            >
                            <option value="days">Nombre de jours</option>
                            <option value="amount">Montant total brut</option>
                            </select>
                        </>
                        )}
                    </div>
                </div>
            </div>

            <div className='div-rtt'>
                <h3>Possédez-vous un Compte Épargne Temps alimenté en RTT ?* Renseignez uniquement pour les RTT</h3>
                <div className='div-select'>
                    <div className='checkbox-container' id='checkbox-container-line'>
                        <input
                            type="checkbox"
                            checked={hasCET === true}
                            onChange={handleBoutonCETOuiClick}
                            className='input-box'
                            id='input-box-mois'
                        />
                        <label className='label-jours' id='label-mois'>Oui</label>
                        <input
                            type="checkbox"
                            checked={hasCET === false}
                            onChange={handleBoutonCETNonClick}
                            className='input-box'
                            id='input-box-mois'
                        />
                        <label className='label-jours' id='label-mois'>Non</label>
                    </div>

                    <div className='input-select-container'>
                    {hasCET && (
                    <>
                        <input className='input-champ' id='input-champ-select'
                        type="number"
                        value={CETValue}
                        onChange={(e) => {
                            const CETValue = e.target.value;
                            if (CETValue>=0 && CETValue!== '') {
                            setAfficherBoutonSuivantCET(true);
                            } else {
                            setAfficherBoutonSuivantCET(false);
                            }
                            setCETValue(CETValue);
                        }}
                        />
                        <select className='select-box' id='select-box-long'
                        value={CETType}
                        onChange={(e) => setCETType(e.target.value)}
                        >
                        <option value="days">Nombre de jours</option>
                        <option value="amount">Montant total brut</option>
                        </select>
                    </>
                    )}
                    </div>
                </div>
            </div>

            <div className='div-legend'>
                <p>Attention de bien prendre en compte tous les RTT qui vont ont été payés à chaque fin de contrat si vous avez eu plusieurs emplois durant votre période de référence (cas 3 de l'étape 2 sur 3 du Calcul de la durée d'indemnisation). Pour cela, renseignez le Montant total brut en sélectionnant cette option dans le menu déroulant car Nombre de jours calcule en utilisant votre dernier salaire mensuel brut.</p>
            </div> 

            <div className='button-container'>
                <button type="button" onClick={retourPrime} className='button-blue'><i class="fa-solid fa-arrow-left"></i>Précédent</button>
                {(!afficherBoutonSuivantRTT || !afficherBoutonSuivantCET) && (
                        <button type="sumbit" className='button-grey' id='button-right'>Résulat<i class="fa-solid fa-arrow-right"></i></button>
                    )}
                    {(afficherBoutonSuivantRTT && afficherBoutonSuivantCET) && (
                        <button type="sumbit" className='button-blue' id='button-right'>Résulat<i class="fa-solid fa-arrow-right"></i></button>
                    )}
            </div>

            <div className='div-legend'>
                <p>*Champs obligatoires.</p>
            </div> 
              
            </div>
        </form>  
    )}

    {afficherIndemnite &&(
        <div>
        <div className='resultat-final'>
            {(indemniteJournaliere < 91.02 || age >= 57) && (
            <div>
                <h1>Votre indemnité mensuelle brute¹ sera de {indemniteMensuelle}€, ce qui correspond à une indemnité journalière brute¹ de {indemniteJournaliere}€.</h1>
            </div>
            )}
            {((indemniteJournaliere > 91.02 && indemniteJournaliere < 130.02) && age < 57) && (
            <div>
                <h1>Votre indemnité mensuelle brute¹ sera de {indemniteMensuelle}€ pendant les 6 premiers mois, ce qui correspond à une indemnité journalière brute¹ de {indemniteJournaliere}€.</h1>
                <h1>À partir du septième mois, votre indemnité journalière brute¹ passera à 91,02€.</h1>
            </div>          
            )}
            {((indemniteJournaliere > 130.02) && age < 57) && (
            <div>
                <h1>Votre indemnité mensuelle brute¹ sera de {indemniteMensuelle}€ pendant les 6 premiers mois, ce qui correspond à une indemnité journalière brute¹ de {indemniteJournaliere}€.</h1>
                <h1>À partir du septième mois, votre indemnité journalière brute¹ passera à {(indemniteJournaliere*0.7).toFixed(2)}€.</h1>
            </div>          
            )}
            <br />
            <p className='p-legend'>¹Il faudra déduire de votre indemnité brute les éventuelles cotisations pour la retraite complémentaire et sociales, ainsi que l'impôt sur le revenu pour obtenir le montant que vous toucherez réellement.</p>

            <h3>Dans votre cas, l'indemnité journalière brute a été calculée de la manière suivante :</h3>
            <div class='result-box'>
                {(indemniteJournaliere > plafondIndemnite && (indemniteJournaliere > 31.59 &&  indemniteJournaliere < 289.64)) && (
                <p className='result'>0.75 * SJR (Salaire Journalier de Référence)</p>
                )} 
                {(indemniteJournaliere === 31.59) && (
                <p className='result'>Minimum réglementaire : {indemniteJournaliere}€.</p>
                )} 
                {(indemniteJournaliere === 289.64) && (
                <p className='result'>Maximum réglementaire : {indemniteJournaliere}€.</p>
                )} 
                {(indemniteJournaliere > 31.59 &&  indemniteJournaliere < 289.64) && (
                <p className='result'>0.57 * SJR (Salaire Journalier de Référence)</p>
                )}  
            </div>
        </div>
        <br />

        <div>
            <div className='evenements'>
                <p>Avec :</p>
                <ul className='liste-evenements'>
                    <li>SJR : (Montant total des salaires reçus + primes + monétisation des RTT) / Nombre de jours travaillés durant la période de référence = {SJR}€</li>
                    <li>Montant total des salaires bruts reçus : {salairePeriode}€</li>
                    <li>Montant total des primes en brut : {montantPrimes}€</li>
                    <li>Monétisation des RTTs payés à la fin du contrat : {montantRTT}€</li>
                    <li>Monétisation des RTTs dans le CET : {montantCET}€</li>
                    <li>Nombre de jours travaillés durant la période de référence ({dateToString(dateDebutPeriode, 'short')} - {dateToString(dateFin, 'short')}) : {nbJoursTravailles} jours</li>
                </ul>
            </div>
            <br />
            <p className='home-legend'>Le résultat obtenu est une estimation réalisée sur la base des seuls éléments demandés par le simulateur. Votre indemnité pourra dépendre de plus de facteurs non pris en compte ici selon votre cas particulier.</p> 
        </div>
        <br />

        <div className='div-mail'>
            <div className='mail-title'>
                <h2>Envie de changer de vie ?</h2> 
                <h2>Un projet de reconversion professionnelle ?</h2>
                <h2>Simplement curieux ? </h2>
            </div>
            <h3>Laissez-nous votre mail pour bientôt recevoir du contenu concernant les <u>formations finançables par le Pôle emploi</u> pour mener à bien tous vos projets !</h3>
            <form className='label-container' onSubmit={envoiMail}>
                <div>
                    <label>Mail :</label>
                    <input className='input-champ' id='input-mail'
                        type="email"
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                        required='type="email"'
                    />
                </div>
                {!mailEnvoyé &&
                    <input type="submit" value="Envoyer" className='button-blue' />
                }
                {mailEnvoyé &&
                    <input type="submit" value="Mail envoyé !" className='button-grey' />
                }
            </form>
        </div>

        <div className='button-container'>
            <button type="button" onClick={retourRTT} className='button-blue'><i class="fa-solid fa-arrow-left"></i>Précédent</button>
            <button onClick={handlePrint} className='button-blue' id='button-right'>Imprimer cette page</button>
        </div>
        
        </div>
    )}
</div>
);
}

export default SimulateurSimple;