import React from 'react';
import { Link } from 'react-router-dom';
import './error.css';

function Error() {
    return (
        <div className='error'>
            <h1>Oups cette page n'existe pas 🙈</h1>
            <div className='error-link'>
                <Link to="/">Retourner à l'accueil</Link>   
            </div>
        </div>
    )
}
 
export default Error